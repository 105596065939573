@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-prev:before,
.slick-next:before {
  color: #f19334;
  font-size: 30px;
  font-weight: bold;
}

.slick-slider {
  width: 100%;
}

@media screen and (max-width: 1920px) {
  .slick-slide {
    padding: 50px;
  }
}

/* Preenchimento para telas com largura máxima de 1366px */
@media screen and (max-width: 1366px) {
  .slick-slide {
    padding: 30px;
  }
}

.slick-track {
  top: 11px;
}

.slick-cloned {
  width: 50px;
}

.slide {
  transform: scale(0.68);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #f19334;
  font-size: 10px;
  font-weight: bold;
}